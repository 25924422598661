<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <v-icon>layers</v-icon>
            Trial Balance
            <v-spacer></v-spacer>
            <v-speed-dial
              v-model="fab"
              :top="true"
              :right="true"
              direction="left"
              transition="slide-y-transition"
              style="margin-top: -30px; margin-right: -30px"
            >
              <v-btn
                slot="activator"
                v-model="fab"
                color="blue darken-2"
                dark
                small
                fab
              >
                <v-icon>filter_list</v-icon>
                <v-icon>close</v-icon>
              </v-btn>
              <v-btn fab dark small color="red" @click="changeStatus">
                <v-icon>view_column</v-icon>
              </v-btn>
              <v-btn fab dark small color="green" @click="changeDefaultStatus">
                <v-icon>vertical_split</v-icon>
              </v-btn>
            </v-speed-dial>
          </v-card-title>

          <v-card-title class="title" style="margin-top: -25px">
            <v-flex xs12 sm3 v-if="status === 'detail'">
              <v-text-field
                label="From Date"
                v-model="trialbalance.filter_date.from_date"
                prepend-icon="event"
                mask="####-##-##"
                placeholder="YYYY-MM-DD"
                :height="25"
              />
            </v-flex>
            <v-flex xs12 sm3>
              <v-text-field
                :label="status === 'detail' ? 'To date' : 'As of Date'"
                v-model="trialbalance.filter_date.to_date"
                prepend-icon="event"
                mask="####-##-##"
                placeholder="YYYY-MM-DD"
                :height="25"
              />
            </v-flex>
            <v-flex xs12 sm3 v-if="status === 'default'"></v-flex>
            <v-flex xs12 sm6 style="text-align: right">
              <v-btn
                fab
                small
                style="z-index: 1; background: blue"
                dark
                @click="DownloadTrial"
              >
                <v-icon dark>cloud_download</v-icon>
              </v-btn>

              <!--<v-spacer/>-->
              <add-button
                :disabled="fetchingTrials"
                permission="section-create"
                @action="getTrials"
                :icon="icon"
                >Search
              </add-button>
            </v-flex>
          </v-card-title>
        </v-card>

        <div class="v-datatable v-table theme--light">
          <table
            class="reporting_acc_table"
            border="1"
            padding="0"
            style="min-width: 1080px"
            v-if="status == 'detail'"
          >
            <thead>
              <tr>
                <th style="width: 50px" rowspan="2">SN</th>
                <th rowspan="2" style="text-align: left">Heads of Account</th>
                <th style="width: 50px" rowspan="2">Ref</th>
                <th colspan="2">Opening Balance</th>
                <th colspan="2">Transaction</th>
                <th colspan="2">Closing Balance</th>
              </tr>
              <tr>
                <th style="width: 95px">Debit</th>
                <th style="width: 95px">Credit</th>
                <th style="width: 95px">Debit</th>
                <th style="width: 95px">Credit</th>
                <th style="width: 95px">Debit</th>
                <th style="width: 95px">Credit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(t, k) in tree" :key="k">
                <th valign="top" style="padding-top: 2px">{{ k + 1 }}</th>
                <td colspan="11">
                  <tree-with-detail
                    :tree-data="t"
                    :root="k"
                    @
                  ></tree-with-detail>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="3" style="padding: 0 5px; text-align: left">
                  Difference
                </th>
                <th style="padding: 0 5px; text-align: right">
                  <span v-if="openingDiff < 0">{{
                    openingDiff | fixAccount
                  }}</span>
                </th>
                <th style="padding: 0 5px; text-align: right">
                  <span v-if="openingDiff > 0">{{
                    openingDiff | fixAccount
                  }}</span>
                </th>
                <th style="padding: 0 5px; text-align: right">-</th>
                <th style="padding: 0 5px; text-align: right">-</th>
                <th style="padding: 0 5px; text-align: right">
                  <span v-if="openingDiff < 0">{{
                    openingDiff | fixAccount
                  }}</span>
                </th>
                <th style="padding: 0 5px; text-align: right">
                  <span v-if="openingDiff > 0">{{
                    openingDiff | fixAccount
                  }}</span>
                </th>
              </tr>
              <tr>
                <th colspan="3" style="padding: 0 5px; text-align: left">
                  Total
                </th>
                <th style="padding: 0 5px; text-align: right">
                  <span v-if="openingDiff < 0">{{
                    (Math.abs(positive) + Math.abs(openingDiff)) | fixAccount
                  }}</span>
                  <span v-else>{{ positive | fixAccount }}</span>
                </th>
                <th style="padding: 0 5px; text-align: right">
                  <span v-if="openingDiff > 0">{{
                    (Math.abs(negative) + Math.abs(openingDiff)) | fixAccount
                  }}</span>
                  <span v-else>{{ negative | fixAccount }}</span>
                </th>
                <th style="padding: 0 5px; text-align: right">
                  {{ ptransaction.numberFormat() }}
                </th>
                <th style="padding: 0 5px; text-align: right">
                  {{ ntransaction.numberFormat() }}
                </th>
                <th style="padding: 0 5px; text-align: right">
                  <span v-if="openingDiff < 0">{{
                    (Math.abs(closingD) + Math.abs(openingDiff)) | fixAccount
                  }}</span>
                  <span v-else>{{ closingD | fixAccount }}</span>
                </th>
                <th style="padding: 0 5px; text-align: right">
                  <span v-if="openingDiff > 0">{{
                    (Math.abs(closingC) + Math.abs(openingDiff)) | fixAccount
                  }}</span>
                  <span v-else>{{ closingC | fixAccount }}</span>
                </th>
              </tr>
            </tfoot>
          </table>

          <table
            border="1"
            padding="0"
            v-if="status == 'default'"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th style="width: 50px" rowspan="2">SN</th>
                <th rowspan="2" style="text-align: left">Heads of Account</th>
                <th style="width: 50px" rowspan="2">Ref</th>
                <th colspan="2">Balance</th>
              </tr>
              <tr>
                <th style="width: 100px">Debit</th>
                <th style="width: 100px">Credit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(t, k) in tree" :key="k">
                <th valign="top" style="padding-top: 2px">{{ k + 1 }}</th>
                <td colspan="4">
                  <tree
                    :tree-data="t"
                    :root="k"
                    :fdate="frmDate"
                    :tdate="toDate"
                  ></tree>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="3" style="text-align: left; padding: 0px 5px">
                  Difference
                </th>
                <th style="text-align: right; padding: 0px 5px">
                  <span v-if="diff < 0">{{ diff | fixAccount }}</span>
                </th>
                <th style="text-align: right; padding: 0px 5px">
                  <span v-if="diff > 0">{{ diff | fixAccount }}</span>
                </th>
              </tr>
              <tr>
                <th colspan="3" style="text-align: left; padding: 0px 5px">
                  Total
                </th>
                <th style="text-align: right; padding: 0px 5px">
                  <span v-if="diff < 0">{{
                    (Math.abs(closingD) + Math.abs(diff)) | fixAccount
                  }}</span>
                  <span v-else>{{ closingD | fixAccount }}</span>
                </th>
                <th style="text-align: right; padding: 0px 5px">
                  <span v-if="diff > 0">{{
                    (Math.abs(closingC) + Math.abs(diff)) | fixAccount
                  }}</span>
                  <span v-else>{{ closingC | fixAccount }}</span>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Tree from "@/components/Generic/TrialTree/Tree";
import TreeWithDetail from "@/components/Generic/TrialTree/TreeWithDetail";
import { mapState } from "vuex";
import { passDataToExternalDomain } from "../../../../library/helpers";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
export default {
  name: "TrialBalance",
  props: {
    treeData: Object,
    fDate: String,
    tDate: String,
  },
  components: { Tree, TreeWithDetail },
  data: () => ({
    tree: [],
    fab: false,
    status: "default",

    event: 0,

    sheet: false,
    frmDate: nd.format("YYYY-MM-DD"),
    toDate: nd.format("YYYY-MM-DD"),
    fetchingTrials: false,
    icon: "search",
  }),
  created() {
    this.getTrials();
  },
  watch: {
    "trialbalance.filter_date.from_date": function (val) {
      this.trialbalance.filter_date.from_date = this.dateFormat(val);
    },
    "trialbalance.filter_date.to_date": function (val) {
      this.trialbalance.filter_date.to_date = this.dateFormat(val);
    },
  },
  computed: {
    ...mapState(["trialbalance"]),
    positive() {
      return this.tree.reduce((a, b) => (b.balance > 0 ? a + b.balance : a), 0);
    },
    negative() {
      return this.tree.reduce((a, b) => (b.balance < 0 ? a + b.balance : a), 0);
    },
    ptransaction() {
      return this.tree.reduce((a, b) => (b.sum_dr > 0 ? a + b.sum_dr : a), 0);
    },
    ntransaction() {
      return this.tree.reduce((a, b) => (b.sum_cr > 0 ? a + b.sum_cr : a), 0);
    },
    closingC() {
      if (this.tree[1] && this.tree[3]) {
        return (
          this.tree[1].balance +
          this.tree[1].sum_dr -
          this.tree[1].sum_cr +
          (this.tree[3].balance + this.tree[3].sum_dr - this.tree[3].sum_cr)
        );
      } else {
        return 0;
      }

      // return this.tree.reduce((a, b) =>  (a + (b.sum_dr + b.balance - b.sum_cr)), 0)
    },
    closingD() {
      if (this.tree[0] && this.tree[2]) {
        return (
          this.tree[0].balance +
          this.tree[0].sum_dr -
          this.tree[0].sum_cr +
          (this.tree[2].balance + this.tree[2].sum_dr - this.tree[2].sum_cr)
        );
      } else {
        return 0;
      }
    },
    diff() {
      return Math.abs(this.closingD) - Math.abs(this.closingC);
    },
    openingDiff() {
      return Math.abs(this.positive) - Math.abs(this.negative);
    },
  },

  methods: {
    getTrials() {
      // this.trialbalance.filter_date.from_date ;
      // this.trialbalance.filter_date.to_date = this.dateFormat(this.toDate);
      if (this.status === "default") {
        this.trialbalance.filter_date.from_date = "";
      }
      this.fetchingTrials = true;
      this.icon = "pending";

      this.$rest
        .get(
          "/api/trial-balance?type=trial-balance&frmDate=" +
            this.trialbalance.filter_date.from_date +
            "&toDate=" +
            this.trialbalance.filter_date.to_date
        )
        .then(({ data }) => {
          this.tree = data;
        })
        .finally(() => {
          this.fetchingTrials = false;
          this.icon = "search";
        });
    },

    getOpening(type) {
      let amount =
        this.positive + this.ntransaction - this.ptransaction + this.negative;
      if (type === "debit") {
        return amount > 0 ? amount.numberFormat() : "";
      } else if (type === "credit") {
        return amount < 0 ? amount.numberFormat() : "";
      }
    },
    dateFormat(data) {
      if (data.length === 8)
        return (data =
          data.substr(0, 4) + "-" + data[4] + data[5] + "-" + data.slice(-2));
      else return data;
    },
    changeStatus() {
      this.status = "detail";
      this.trialbalance.filter_date.from_date = nd.format("YYYY-MM-DD");
      this.getTrials();
    },
    changeDefaultStatus() {
      this.status = "default";
      this.getTrials();
    },
    DownloadTrial() {
      let reportType = "trial";
      if (this.status === "detail") {
        reportType = "trial_detail";
      }
      this.$rest.get("api/download/trial-balance").then((data) => {
        let url =
          data.data.data.download_url +
          "?type=trial&frmDate=" +
          this.trialbalance.filter_date.from_date +
          "&toDate=" +
          this.trialbalance.filter_date.to_date +
          "&reportType=" +
          reportType +
          "&hideZero=" +
          true+"&token="+this.$auth.user().id;
        passDataToExternalDomain();
        setTimeout(() => {
          window.open(url);
        }, 300);
      });
    },
  },
  filters: {
    fixAccount(item) {
      if (!isNaN(item))
        return item >= 0 ? item.numberFormat() : `${(-item).numberFormat()}`;
    },
  },
};
</script>
<style lang="scss">
table.reporting_acc_table {
  width: 100%;
  background: white;

  thead {
    th {
      padding: 5px;
    }

    tr {
      border: 1px solid black;
    }
  }

  tbody {
    tr {
      border: 1px solid black;
    }
  }

  .label {
    cursor: pointer;
  }
}
</style>
